<template>
  <div id="solutions" class="px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Technographics</b> - <i>Technographic Coverage</i>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="treeData === null">
        <content-placeholders>
          <content-placeholders-text :lines="2"></content-placeholders-text>
        </content-placeholders>
        <hr />
        <content-placeholders>
          <content-placeholders-img
            class="table-holder"
          ></content-placeholders-img>
        </content-placeholders>
      </div>
      <div v-else>
        <b-row>
          <b-col sm="6">
            <h5 class="mb-0">Solution Hierarchy</h5>
          </b-col>
          <b-col sm="6" class="text-right">
            <h6 class="mb-0">
              Click on a category for a list of Vendor Solutions
            </h6>
          </b-col>
        </b-row>
        <hr class="style-one mt-1" />
        <b-row>
          <b-col sm="6">
            <div class="search-bar">
              <b-input-group>
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-search fa-lg"></i>
                  </span>
                </div>
                <b-form-input
                  id="prependedInput"
                  class="form-control no-focus-highlt"
                  size="lg"
                  type="text"
                  placeholder="Type Technographic Solution to Select"
                  ref="searchBox"
                  v-model="searchText"
                >
                </b-form-input>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <LiquorTree
              :data="treeData"
              :multiple="false"
              :showChildren="true"
              :parentSelect="false"
              v-model="selectedNode"
              :options="treeOptions"
              :filter="searchText"
              ref="solutionTree"
              @tree:mounted="sortLeafFirst"
            >
              <span class="tree-text" slot-scope="{ node }">
                <template v-if="!node.hasChildren()">
                  <router-link
                    :key="node.data.id"
                    :to="{
                      path: '/technographics/service_report/' + node.data.id
                    }"
                  >
                    <i class="fa fa-bank fa-sm pr-1"></i>{{ node.text }}
                  </router-link>
                </template>

                <template v-else>
                  <i></i>
                  <span class="node-parent"
                    ><i class="fa fa-bank fa-sm pr-1"></i>{{ node.text }}</span
                  >
                </template>
              </span>
            </LiquorTree>
          </b-col>
          <b-col sm="3">
            <b-card class="bg-success base-color mt-3">
              <div slot="header">
                <div class="text-center">
                  <h5>Technographic Request</h5>
                </div>
              </div>
              <div>
                <div>
                  Don't see a category you are looking for? Click here to add a
                  Technographic.
                </div>
                <div class="text-center mt-3">
                  <router-link
                    tag="button"
                    class="btn btn-primary fw-btn"
                    :to="{ name: 'request-solution' }"
                  >
                    Request Solution
                  </router-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col sm="3"> </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import LiquorTree from 'liquor-tree'
import LiquorTreeSort from '@/mixins/LiquorTreeSort'

Vue.use(LiquorTree)

export default {
  name: 'technographic-solutions',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$http
        .get('/api/bank_services/tree', { handleErrors: true })
        .then(response => {
          vm.treeData = response.data.results
        })
    })
  },
  beforeCreate() {
    document.title = 'FIN Technographics'
  },
  components: {
    LiquorTree
  },
  mixins: [LiquorTreeSort],
  data: function() {
    return {
      treeData: null,
      selectedNode: null,
      searchText: '',
      treeOptions: {
        plainList: true,
        showChildren: true,
        filter: {
          emptyText: 'No solutions found for the criteria'
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
table-holder {
  min-height: 20rem;
}
i.has-child {
  margin-top: 1rem;
}
.node-parent {
  font-weight: bold;
}
.tree-text {
  a {
    color: #29363d;
  }
}
.vue-content-placeholders-img {
  height: 25rem;
}
div.solution-button-container {
  text-align: center;
  button {
    background-color: #20a8d8 !important;
    margin-top: 1rem;
  }
}
.style-one {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #ccc, #ccc);
}

.base-color {
  color: initial;
}
</style>
