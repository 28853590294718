export default {
  methods: {
    sortLeafFirst() {
      let solutionTree = this.$refs.solutionTree
      solutionTree.sortTree(
        function(node1, node2) {
          return this.hasChildren(node1, node2)
            ? this.applySort(node1, node2)
            : 0
        }.bind(this)
      )
    },
    hasChildren(node1, node2) {
      return node1.children.length > 0 || node2.children.length > 0
    },
    applySort(node1, node2) {
      return node1.children.length > 0 && node2.children.length > 0 ? 0 : 1
    }
  }
}
